.choose-amount-card-currency-container {
  display: flex;
  gap: 20px;
}

.currency-name {
  font-size: 40px;
  font-weight: 900;
}

.choose-amount-card-container {
  width: 100%;
  border-radius: 24px;
  box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -webkit-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -moz-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
}

.choose-amount-card-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.change-currency-amount-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.selected-currency-amount {
  font-size: 32px;
  font-weight: 700;
}

.amount-title {
  font-size: 32px;
}

.currency-rate {
  font-size: 28px;
}
.choose-amount-card-bottom-container {
  border-top: 2px dashed #dadada;
  padding: 30px 0px;
}

.currency-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.max-disclaimer {
  font-size: 28px;
}

.max-disclaimer-bold {
  font-weight: 800;
}
