.counter {
	width: 216.263px;
	height: 61.681px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #283760;
	font-family: "Noto Sans Hebrew";
	font-size: 24.368px;
	font-style: normal;
	font-weight: 700;
	line-height: 109%;
}

.counter.mini {
	width: 170.676px;
	height: 48.679px;
}

.counter.mini > button {
	width: 48.078px;
	height: 48.078px;
	background-size: 26px 26px;
}

.button {
	width: 60.919px;
	height: 60.919px;
	flex-shrink: 0;
	border-radius: 50%;
	border: solid 2px #283760;
}

.button.increment {
	background: url("../../assets/plus.svg") no-repeat 50% 50% #ffffff;
}

.button.decrement {
	background: url("../../assets/minus.svg") no-repeat 50% 50% #dadada;
	border: none;
}
