.totalCurrency {
	width: 95%;
}

.countPayments {
	height: 110px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8.013px 8.013px 8.013px 0px;
	background: #ebf2fc;
	padding: 0 56px;
}

.countText {
	color: #283760;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 25.641px;
	font-style: normal;
	font-weight: 400;
	line-height: 109%;
}

.countText.confirm {
	color: #283760;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 109%;
}

.totalValue {
	display: grid;
	grid-template-columns: 2fr 1fr;
	padding: 45px 50px;
	width: 100%;
	height: 184.298px;
	flex-shrink: 0;
	border-radius: 0px 0px 8.013px 8.013px;
	background: #283760;
	gap: 30px;
}

.totalValue.confirm {
	grid-template-columns: 1fr 1fr;
}

.totalPayable {
	text-align: right;
	color: #f6f8fc;
	font-family: "Noto Sans Hebrew";
	font-size: 32.052px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
}

.totalPayable.confirm:nth-child(2) {
	text-align: left;
}

.totalPayment {
	color: #fff;
	text-align: right;
	font-family: "Noto Sans Hebrew";
	font-size: 22.436px;
	font-style: normal;
	font-weight: 400;
	line-height: 109%;
}

.totalPayment.confirm {
	color: #f6f8fc;
	font-family: "Noto Sans Hebrew";
	font-size: 25px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
}

.totalPayment.confirm:last-child {
	text-align: left;
}

.count {
	width: 170px;
	color: #283760;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 109%;
}

.total {
	height: fit-content;
	position: relative;
	width: 100%;
}

.hr {
	position: absolute;
	border: none;
	background: linear-gradient(to right, transparent 50%, #dadada 0%) repeat-x bottom;
	background-size: 10px 1px;
	height: 0.5px;
	width: 96%;
	top: 50%;
	margin: auto;
	left: 2%;
}
