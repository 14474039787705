.order-currency-summary-container {
  width: 100%;
  display: flex;
  border-radius: 24px;
  box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -webkit-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  -moz-box-shadow: 1px 5px 20px -5px rgba(40, 55, 96, 0.2);
  justify-content: space-between;
  padding: 50px;
  align-items: center;
}

.currency-container {
  display: flex;
  gap: 20px;
}
.currency-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.summary-currency-container {
  background-color: #e3e9f1;
  padding: 20px 40px;
  border-radius: 8px;
}

.order-summary-top {
  width: 100%;
}

.summary-currency-text {
  font-size: 28px;
  font-weight: 700;
}

.summary-currency-big-text {
  font-size: 40px;
  font-weight: 900;
}

.summary-rate-text {
  font-size: 28px;
}
