.title {
  font-size: 60px;
  font-weight: 800;
  color: #283760;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 20px;
}

.message-text {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  color: #283760;
}

.contact-info-container {
  display: flex;

  gap: 10px;
}

.bottom-container {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 27px;
  color: #283760;
  gap: 10px;
  position: relative;
  top: 50px;
}

.main-container {
  display: flex;
  justify-content: space-between;
}