.container {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.content {
	width: 600px;
}

.title {
	color: var(--blue, #283760);
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
	margin: 0 0 50px;
}

.phoneInput {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 64px;
}

.buttons {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	width: min(100%, 700px);
	margin-top: 60px;
	margin-bottom: 190px;
}

.arrowRight {
	transform: rotate(180deg);
}

.arrowRight > svg > g > path {
	stroke: #283760;
}

.buttons > button {
	border: 0px !important;
}


.subtitle{
	font-size: 32px;
	font-weight: 700;
}

.doc_type_btn{
	border: 1px solid #283760;
    font-size: 32px;
    color: #283760;
    border-radius: 10px;
    width: 200px;
    height: 80px;
    line-height: 70px;
}


.doc_type_btn_cont{
	display: flex;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.selected_type{
	background-color: #283760;
	color:white
}

.date_birth{
    display: flex;
	flex-direction: row;
	align-items: center;
	width: 600px;
	gap: 15px;
	position: relative;
	height: 120px;
	border-radius: 15px;
    justify-content: flex-end;
}

.date_birth_img{
	background: url("../../assets/identity-number.svg") no-repeat scroll calc(100% - 36px) 40px;
	border: 1px solid #8080804d;
	border-top-right-radius: 15px; 
    border-bottom-right-radius: 15px; 
	width: 115px;
	height: 120px;
}

.date_birth_input_year{
	border: 1px solid #8080804d;
	width: 200px;
	height: 120px;
	position: relative;
}

.date_birth_input_month{
	border: 1px solid #8080804d;
	width: 120px;
	height: 120px;
	position: relative;
}

.year_text{
	position: absolute;
    bottom: 4px;
    left: 75px;
    color: #717171;
    font-size: 20px;
}

.month_text{
	position: absolute;
    bottom: 4px;
    left: 32px;
    color: #717171;
    font-size: 20px;
}

.day_text{
	position: absolute;
	bottom: 4px;
	left: 45px;
	color: #717171;
	font-size: 20px;
}

.date_birth_input_day{
	border: 1px solid #8080804d;
	width: 120px;
	height: 120px;
	position: relative;
	border-top-left-radius: 15px; 
    border-bottom-left-radius: 15px; 
}

.date_birth_input{
	width: 25px;
    border: 0;
    border-bottom: 4px solid #717171;
    font-size: 32px;
    color: #717171;
    margin-right: 8px;
    margin-top: 40px;
}

.date_birth_sep{
	color:#717171;
	font-size: 32px;
}

.birth_date_title{
	width: 100%;
    color: #283760;
    text-align: right;
    font-family: "Noto Sans Hebrew";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
	margin-bottom: -50px;
}

.date_birth_input:focus{
    outline: none;
}

.date_valid{
	border:1px solid #f8a801
}


.date_notvalid{
	border:1px solid #c93d4e
}


.errorMessage {
	width: 100%;
	color: var(--worning, #c93d4e);
	text-align: right;
	font-family: "Noto Sans Hebrew";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 0;
	display: flex;
	align-items: center;
	gap: 6px;
	margin: 0;
	margin-top: -50px;
}

.errorMessage::before {
	display: inline-block;
	content: "";
	height: 40px;
	width: 40px;
	background: url("../../assets/error.svg");
}

.successIcon {
	position: absolute;
	left: 21px;
	top: 45px;
}
