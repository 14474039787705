.container {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.content {
	width: 600px;
}

.title {
	color: var(--blue, #283760);
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
	margin: 0 0 50px;
}

.phoneInput {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 64px;
}

.buttons {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: min(100%, 700px);
    margin-top: 60px;
}

.arrowRight {
	transform: rotate(180deg);
}

.arrowRight > svg > g > path {
	stroke: #283760;
}

.buttons > button {
	border: 0px !important;
	width: 70%;
}


.video_place{
	background-color: #DFE6F1;
	height: 540px;
	border-radius: 24px;
}


.video_place_text{
	font-size: 32px;
	font-weight: 700;
	padding-top: 225px;
}

.camera_icon{
	margin-top: 184px;
}

.sub_title{
	font-size: 48px;
	margin-top: 20px;
}




.scand_tz{
	width: 100%;
	margin-bottom: 20px;
}
