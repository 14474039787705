.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  padding-top: 100px;
}

.currencies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}

.title {
  font-size: 60px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: #283760;
}

.button {
  border-color: "#fff";
}

.button-icon {
  width: 8px;
  height: 16px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
