.container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
}

.buyingSVG {
	width: 90vw;
	height: 30vh;
	margin: 74px auto;
}

.title {
	box-sizing: border-box;
	color: #283760;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 60px;
	font-style: normal;
	font-weight: 800;
	line-height: 109%;
	width: min(100%, 700px);
	white-space: pre-line;
}

.text {
	color: #283760;
	text-align: center;
	font-family: "Noto Sans Hebrew";
	font-size: 38px;
	font-style: normal;
	font-weight: 400;
	line-height: 109%;
}

.operations {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
}

.operation {
	display: flex;
	align-items: center;
	gap: 16px;
	width: fit-content;
	height: fit-content;
}

.paymentsCard {
	margin: 50px 0;
	width: 80%;
}

.buttons {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
