.left-arrow {
  transform: rotate(180deg);
}

.choose-amount-title {
  font-size: 60px;
  font-weight: 700;
}

.choose-amount-disclaimer {
  font-size: 32px;
}
.choose-amount-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  width: 100%;
}

.modal-text {
  font-size: 44px;
  padding-top: 40px;
  font-weight: 500;
}

.modal-inner-container {
  height: 100%;
  padding: 80px 0;
  border: 1px solid #c93d4e;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-amount-subtitle {
  font-size: 32px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 190px;
}
