.modal.close {
  display: none;
  height: 100svh;
  width: 100vw;
}

.overflow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  opacity: 0.5;
  background: var(--blue, #283760);
  top: 0;
}

.container {
  width: 720px;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--white, #fff);
  box-shadow: 4px 4px 20px 0px rgba(40, 55, 96, 0.2);
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  flex: 1 1 auto;
}

.header {
  height: 100px;
  position: absolute;
}

.crossSVG {
  top: 30px;
  right: 30px;
  position: absolute;
}
