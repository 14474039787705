.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: min(100%, 800px);
}

.currencies {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 30px;
}

.heading {
	text-align: left;
	width: 95%;
	color: #283760;
	font-family: "Noto Sans Hebrew";
	font-size: 24.368px;
	font-style: normal;
	font-weight: 400;
	line-height: 109%;
	padding-left: 17px;
}
